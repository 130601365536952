import { Footnotes } from '@contexts/footnotes'
import { NavbarHeightProvider } from '@contexts/navbar-height'
import { useTrackPageLoad } from '@hooks/use-track-page-load'
import { useTrackingParameters } from '@hooks/use-tracking-parameters'
import { ParametersProvider } from '@utils/parameters'
import type { GatsbySSR } from 'gatsby'
import React, { type FC } from 'react'

const TrackParameters: FC = () => {
  useTrackingParameters()
  return null
}

const TrackPageLoad: FC<{ pathname: string; data: any }> = ({
  pathname,
  data,
}) => {
  useTrackPageLoad(pathname, data)
  return null
}

// Fixme should be GatsbySSR | GatsbyBrowser but crash cause of any type
export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({
  element,
  props: {
    data,
    location: { pathname },
  },
}) => (
  <ParametersProvider>
    <TrackPageLoad data={data} pathname={pathname} />
    <TrackParameters />
    <Footnotes key={pathname}>
      <NavbarHeightProvider>{element}</NavbarHeightProvider>
    </Footnotes>
  </ParametersProvider>
)
