// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { query } from '@entries/entry-fragment'

export enum ContentType {
  ContentfulAsset = 'ContentfulAsset',
  ContentfulBlogArticle = 'ContentfulBlogArticle',
  ContentfulBlogTag = 'ContentfulBlogTag',
  ContentfulBlogAuthor = 'ContentfulBlogAuthor',
  ContentfulCommunicationBanner = 'ContentfulCommunicationBanner',
  ContentfulContentArticle = 'Contentful4TmD9EihHakmKMkEwKaUwQ',
  ContentfulGraph = 'ContentfulGraphs',
  ContentfulConversionArea = 'ContentfulConversionArea',
  ContentfulCta = 'ContentfulCta',
  ContentfulDeprecatedEtapier = 'Contentful3KdsMhn4HaYi80YIgAOoYq',
  ContentfulDeprecatedPage = 'Contentful3TUjMz7TxKWiQYygwIysig',
  ContentfulEmbeddedAsset = 'ContentfulEmbeddedAsset',
  ContentfulFootnote = 'ContentfulFootnote',
  ContentfulForm = 'ContentfulLandingSouscriptionMarqueBlancheV2',
  ContentfulHighlightBlock = 'ContentfulHighlightBlock',
  ContentfulImage = 'ContentfulImage',
  ContentfulInternalLink = 'ContentfulInternalLink',
  ContentfulNavigationGroup = 'ContentfulNavigationGroup',
  ContentfulNavigationItem = 'ContentfulNavigationItem',
  ContentfulOffer = 'Contentful10SSpX3YFi0CgK40EseM2G',
  ContentfulPage = 'ContentfulPage',
  ContentfulPdfLink = 'ContentfulPdfLink',
  ContentfulStaticPage = 'ContentfulStaticPage',
  ContentfulPageSection = 'ContentfulSection',
  ContentfulQuestionGroup = 'ContentfulQuestionGroup',
  ContentfulQuestion = 'ContentfulQuestion',
  ContentfulPlatformScore = 'ContentfulPlatformScore',
  ContentfulReview = 'Contentful4VKja7XjbWkW4WeI4OqeUi',
  ContentfulSeo = 'ContentfulSeo',
  ContentfulSite = 'ContentfulSite',
  ContentfulStageGroup = 'ContentfulStageGroup',
  ContentfulStage = 'ContentfulStage',
  ContentfulStaticBlock = 'ContentfulStaticBlock',
  ContentfulWrapperInbentaContentList = 'ContentfulWrapperInbentaContentList',
  ContentfulWrapperInbentaPopularContents = 'ContentfulWrapperInbentaPopularContents',
  ContentfulWrapperRichMedia = 'ContentfulWrapperRichMedia',
  InbentaKmContent = 'InbentaKmContent',
  InbentaKmCategory = 'InbentaKmCategory',
  NetreviewsReview = 'NetreviewsReview',
  TrustpilotReview = 'TrustpilotReview',
}
